import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from 'src/components/bio'
import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import { rhythm } from 'src/utils/typography'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[`blog`, `programming`, `typescript`, `javascript`, `c#`]}
        />
        <Bio />
        <ol className="articleList">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            const preview = node.snippet;
            const updates = node.frontmatter.updates || [];
            const nonMinorUpdates = updates.filter(u => !u.minor);
            const mostRecentNonMinorUpdate = nonMinorUpdates.slice(-1)[0]
            return (
              <li key={node.fields.slug}>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  <article>
                    <h2
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      {title}
                    </h2>
                    {
                      
                      <small>Published {node.frontmatter.date}.{mostRecentNonMinorUpdate && (
                        " Updated " + mostRecentNonMinorUpdate.date + "."
                      ) || null}</small>
                    }
                    <div className="articleBody" dangerouslySetInnerHTML={{ __html: preview }} />
                  </article>
                </Link>
              </li>
            )
          })}
        </ol>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          snippet
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            tags
            updates {
              date(formatString: "YYYY-MM-DD")
              minor
            }
          }
        }
      }
    }
  }
`
